@charset "utf-8";
/*---------------------------------------
utility-border
----------------------------------------*/
//例外的に要素に下線をつけたい場合
.u-bdb {
    border-bottom: 1px solid #aaa
}

.u-bdb--blue {
    border-bottom: 1px solid #8ea6d2
}
