/*---------------------------------------
project-gnav
----------------------------------------*/
.p-gnav {
    & a {
        display: block;
        color: #333;
        text-decoration: none;
        padding: 4vw 4vw 4vw 16vw;
        position: relative;

        &::before {
            content: "";
            width: 8.53vw;
            height: 100%;
            position: absolute;
            top: 0;
            left: 2.66vw;
        }

        &.p-gnav__top::before {
            background: url(/assets/sp/images/icon_gnav_top.png) center no-repeat;
            background-size: contain;
        }

        &.p-gnav__counseling::before {
            background: url(/assets/sp/images/icon_gnav_counseling.png) center no-repeat;
            background-size: contain;
        }

        &.p-gnav__list::before {
            background: url(/assets/sp/images/icon_gnav_list.png) center no-repeat;
            background-size: contain;
        }

        &.p-gnav__guide::before {
            background: url(/assets/sp/images/icon_gnav_guide.png) center no-repeat;
            background-size: contain;
        }

        &.p-gnav__news::before {
            background: url(/assets/sp/images/icon_gnav_news.png) center no-repeat;
            background-size: contain;
        }

        &.p-gnav__faq::before {
            background: url(/assets/sp/images/icon_gnav_faq.png) center no-repeat;
            background-size: contain;
        }

        &.p-gnav__voice::before {
            background: url(/assets/sp/images/icon_gnav_voice.png) center no-repeat;
            background-size: contain;
        }
    }

    &--arrow {
        & li {
            border-bottom: 1px solid #aaa;
            background: url(/assets/sp/images/icon_arrow_right.png) 97% center no-repeat #fff;
            background-size: 2.1vw auto;

            &.p-gnav__accordion {
                background: url(/assets/sp/images/icon_arrow_down.png) 97% center no-repeat #fff;
                background-size: 3.4vw auto;

                &.active {
                    background: url(/assets/sp/images/icon_arrow_up.png) 97% center no-repeat #fff;
                    background-size: 3.4vw auto;
                }
            }
        }
    }

    &--accordion__item {
        display: none;

        & li:last-child {
            border-bottom: none;
        }
    }
}
