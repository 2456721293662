.p-inputText { //<input>に付与
    width: 100%;

    &--search {
        background: url('/assets/sp/images/icon_search.png') 3% 50% no-repeat, #fff;
        background-size: 5.6%;
        padding-left: 4rem;
    }

    &--s {
        width: 37.33vw;
    }
}

.p-inputLabel { //<label>に付与/input横並びの時
    width: 8.5vw;
    display: table-cell;
    vertical-align: middle;
    font-size: 1.5rem;
}

.p-inputList { //cbやRadioなどリストになっているもの
    & li {
        margin-bottom: 2.66vw;
    }

    &__cBLabel {
        padding-left: 9.46vw;
    }

    &--2item { //2カラムになっているもの
        display: flex;
        flex-wrap: wrap;

        & li {
            min-width: 47.3vw;
            display: block;
        }
    }

    &--s { //短め用(性別の項目で使用)
        display: flex;
        flex-wrap: wrap;

        & li {
            width: 26.66vw;
            display: block;
        }
    }
}
