/*---------------------------------------
project-slider
----------------------------------------*/
.p-slider {
    margin: 4vw 2.66vw;

    &__pic {
        margin: .1vw auto;
        padding: 4.7vw;
        border: 1px solid #BBB;
        width: 76.7vw;
        display: block;
    }

    &__cap {
        margin: 1.33vw auto 0;
        width: 79.2vw;
        color: $textGrey;
    }

    &__button {
        position: absolute;
        top: 50%;
        width: 4.26vw;
        height: 6.93vw;
        margin-top: -2.13vw;
        z-index: 10;
        cursor: pointer;
    }

    &__prev {
        background: url('/assets/sp/images/icon_arrow_left.png') 50% no-repeat;
        background-size: contain;
        left: 0;
        @supports (background-image: url('/assets/sp/images/icon_arrow_left.webp')) {
            background-image: url('/assets/sp/images/icon_arrow_left.webp');
        }
    }

    &__next {
        background: url('/assets/sp/images/icon_arrow_right.png') 50% no-repeat;
        background-size: contain;
        right: 0;
    }

    //banner
    &--banner {
        margin-top: 1.33vw;

        &__pic {
            margin: 0 auto;
            width: 80%;
            display: block;
        }

        &__text {
            margin-bottom: 2.66vw;
        }

        &__button {
            position: absolute;
            top: 50%;
            width: 8.79vw;
            height: 8.79vw;
            z-index: 10;
            margin-top: 4.3vw;
            cursor: pointer;
        }

        &__prev {
            background: url('/assets/sp/images/icon_arrow_left_red.png') 50% no-repeat;
            left: 4.66vw;
            background-size: contain;
        }

        &__next {
            background: url('/assets/sp/images/icon_arrow_right_red.png') 50% no-repeat;
            right: 4.66vw;
            background-size: contain;
        }
    }

    //topMV
    &--full {
        padding-bottom: 0;

        &__pic {
            width: 100%;
        }
    }
}

//swiperカスタム用(topMV)
.p-slider--full .p-slider--full__container {
    padding-bottom: 7vw;
}

.swiper-container-horizontal > .p-slider--full__pagenation.swiper-pagination-bullets {
    bottom: 0;
}

.swiper-pagination-bullet-active { //bullet,active時の色
    background: #3D62AC;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet { //bulletのmargin
    margin: 0 1.4vw;
}

.swiper-pagination-bullet { //bulletの大きさ
    width: 2.9vw;
    height: 2.9vw;
}
