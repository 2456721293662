/*---------------------------------------
utility-text
----------------------------------------*/
.u-right {
    text-align: right;
}

.u-left {
    text-align: left;
}

.u-center {
    text-align: center;
}

.u-faceRed {
    color: $textRed;
}

.u-facePink {
    color: $mainButton;
}

.u-faceBlue {
    color: $mainBlue;
}

.u-faceGreen {
    color: $mainGreen;
}

.u-faceGrey {
    color: $textGrey;
}

.u-faceWhite {
    color: #fff;
}

.u-faceBlack {
    color: #333;
}

.u-faceBoldRed {
    color: $textRed;
    font-weight: bold;

    &--l {
        color: $textRed;
        font-weight: bold;
        font-size: $headlineFont;
    }
}

.u-faceBoldPink {
    color: $mainButton;
    font-weight: bold;

    &--l {
        color: $mainButton;
        font-weight: bold;
        font-size: $headlineFont;
    }
}

.u-faceBoldBlue {
    color: $mainBlue;
    font-weight: bold;

    &--l {
        color: $mainBlue;
        font-weight: bold;
        font-size: $headlineFont;
    }
}

.u-faceBoldGreen {
    color: $mainGreen;
    font-weight: bold;

    &--l {
        color: $mainGreen;
        font-weight: bold;
        font-size: $headlineFont;
    }
}

.u-faceBoldGrey {
    color: $textGrey;
    font-weight: bold;

    &--l {
        color: $textGrey;
        font-weight: bold;
        font-size: $headlineFont;
    }
}

.u-faceBoldWhite {
    color: #FFF;
    font-weight: bold;

    &--l {
        color: #FFF;
        font-weight: bold;
        font-size: $headlineFont;
    }
}

.u-bold {
    font-weight: bold;
    &--normal { //fontサイズを通常サイズ(1.5rem)に上書きしたい場合
        font-weight: bold;
        font-size: $mainFont;
    }
    &--l {
        font-weight: bold;
        font-size: $headlineFont;
    }
}

.u-normal { //デフォルトでboldになっているものをnormalにしたい場合等に使用
    font-weight: normal;
}

.u-memo {
    color: $textGrey;

    &--s {
        font-size: $memoFont;
        line-height: 1.5;
    }
}

.u-attention {
    color: $textRed;

    &--normal {
        font-size: 1.5rem;
    }
}

.u-decoNone {
    text-decoration: none;
}