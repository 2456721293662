@charset "utf-8";
/*---------------------------------------
project-table
----------------------------------------*/
.p-table {
    width: 100%;

    & th {
        padding: 2.66vw;
        font-weight: bold;
        text-align: left;
        border-top: 1px solid $lineGrey;
        border-bottom: 1px solid $lineGrey;
    }

    &__grayHead { //thにグレーの背景をつけたい場合
        background-color: $bgGrey;
        width: 30.67vw;
    }

    &__grayHead--l { //thにグレーの背景をつけたい場合ロング
        background-color: $bgGrey;
        width: 74.66vw;
    }

    & td {
        padding: 2.66vw;
        border-top: 1px solid $lineGrey;
        border-bottom: 1px solid $lineGrey;
    }

    &--inbox { //borderが上下左右についているtable(mgBox10などの中に入っている)
        border: 1px solid $lineGrey;
        width: 100%;

        & th {
            padding: 2.66vw 1.5vw;
            width: 25.33vw;
            text-align: left;
            border-top: 1px solid $lineGrey;
            border-bottom: 1px solid $lineGrey;
            background-color: #eee;
        }

        & td {
            padding: 2.66vw;
            text-align: right;
            border-top: 1px solid #aaa;
            border-bottom: 1px solid #aaa;
        }
    }

    &--noborder { //線無しテーブル
        width: 100%;

        & th {
            width: 34vw;
        }

        &__short { //線無しテーブル「：」用
            width: 1vw;
        }

        &__cont { //線無しテーブル 内容用(右側セル)
            padding-left: 1.2vw;
        }
    }
}

.p-table--homeList { //<table>に付与
    border: 1px solid #aaa;
    background: #fff;
    font-size: 1.5rem;
    line-height: 1.5;
    width: 100%;

    & th {
        font-weight: bold;
        text-align: center;
        background-color: #eee;
        line-height: 1.3;
        border: 1px solid #aaa;
    }

    & td {
        border: 1px solid #aaa;
        padding: 2vw;
    }

    &__cbArea {
        padding: 1.5vw 1.6vw;
        width: 12vw;
        text-align: center;
    }

    &__cb::before {
        top: -3.66vw;
        left: -3.66vw;
    }

    &__delete {
        padding: 1.5vw 1.6vw;
        width: 12vw;
        text-align: center;
    }

    &__deleteButton {
        background: url('/assets/sp/images/icon_delete.png') 50% no-repeat;
        background-size: 60%;
    }
}
