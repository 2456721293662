/*---------------------------------------
utility-fontSize
----------------------------------------*/
/*---fontsize---*/
$headlineFont: 1.85rem;
$mainFont: 1.65rem;
$subFont: 1.5rem;
$memoFont: 1.2rem;

.u-headlineFont {
    font-size: $headlineFont;
}

.u-mainFont {
    font-size: $mainFont;
}

.u-subFont {
    font-size: $subFont;
}

.u-memoFont {
    font-size: $memoFont;
}
