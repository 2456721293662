@charset "utf-8";
/*---------------------------------------
Reset Stylesheet
v1.0.0
----------------------------------------*/

/*
Font-size
9px  = 0.9rem		|	15px  = 1.5rem
10px = 1rem (base)	|	16px  = 1.6rem
11px = 1.1rem		|	17px  = 1.7rem
12px = 1.2rem		|	18px  = 1.8rem
13px = 1.3rem		|	19px  = 1.9rem
14px = 1.4rem		|	20px  = 2rem
*/

/* Reset */
html, body,
h1, h2, h3, h4, h5, h6,
div, p, dl, dt, dd, ol, ul, li,
span, a, strong, em, b, i, del, dfn, img, ins, kbd, q, samp, small, sub, sup, var,
table, caption, tbody, tfoot, thead, tr, th, td,
fieldset, form, label, legend,
object, iframe, blockquote, pre, abbr, address, cite, code,
article, aside, canvas, details, figcaption, figure,
footer, header, main, menu, section, nav,
time, mark, video, audio {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: normal;
    font-style: normal;
    vertical-align: baseline;
    background: transparent;
}

/* Typography */
html {
    font-size: 62.5%;
}

@media screen and (max-width: 320px) {
    html {
        font-size: 51.8%;
    }
}

@media screen and (min-width: 481px) and (max-width: 735px) {
    html {
        font-size: 95%;
    }
}

@media screen and (min-width: 736px) {
    html {
        font-size: 125%;
    }
}

body {
    padding-top: 16vw;
    font-family: Noto Sans JP, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
    font-size: 1.65rem;
    font-weight: 400;
    line-height: 1.8;
    color: #333;
    -webkit-text-size-adjust: 100%;

    &.paddingTopNone { //headerをfixedさせないページのみ使用
        padding-top: 0;
    }
}

/* Layout */
/* stylelint-disable-next-line */
html {
    overflow-y: scroll;
}

header, main, menu, nav, section, article, aside, details, figcaption, figure, footer {
    display: block;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

:focus {
    outline: 0;
}

/* Elements */
input, pre, button, select, textarea {
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: initial;
    font-weight: 400;
    vertical-align: middle;
}

img {
    border: 0;
    display: block;
    height: auto;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

caption, th, td {
    text-align: left;
}

ul, ol {
    list-style: none;
}

em, address {
    font-style: normal;
}
