/*---------------------------------------
.socialArea
----------------------------------------*/
.socialArea {
    padding: 14px 10px 20px;
    display: table;
    width: 100%;
    min-height: 40px;
    overflow: visible;
    box-sizing: border-box;
}

.socialArea:last-child {
    margin-bottom: 20px;
    border-top: none;
}

.socialArea .snsBox {
    display: table-cell;
    width: 12.5vw;
    padding-right: 2.5vw;
    vertical-align: top;
}

.socialArea .snsBox:last-child {
    padding-right: 0;
    width: 10.87vw;
}

.socialArea .snsBox a {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
}

.socialArea .snsBox img {
    width: 100%;
    height: auto;
}

.socialArea .fbLike {
    margin-left: 0;
    width: 15.5%;
    min-height: 40px;
    overflow: visible;
    position: relative;
}

.pluginButton {
    width: 100% !important;
    transform: scale(1) !important;
}