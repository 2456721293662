/*---------------------------------------
project-modal
----------------------------------------*/
.p-modal {
    display: none;
    width: 94.67vw;
    max-height: 90%;
    background: #fff;
    position: fixed;
    top: 2%;
    left: 50%;
    transform: translateX(-50%);
    overflow: auto;
    z-index: 1001;
    img {
        width: 100%;
        height: auto;
    }
    &__close{
        position: absolute;
        top: 2.66vw;
        right: 2.66vw;
        a{
            display: inline-block;
            width: 7.5vw;
            height: 7.5vw;
            position: relative;
            span{
                &::before,
                &::after{
                    display: block;
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 9.2vw;
                    height: 0.7vw;
                    margin: 0 0 0 -4.6vw;
                    background: #fff;
                }
                &::before{
                    transform: rotate(-45deg);
                }
                &::after{
                    transform: rotate(45deg);
                }
            }
        }
    }
}
.pg-pointArea {
    &__lead {
        line-height: 1.5;
        font-size: 1.65rem;
    }
    ul {
        background-color: #fcbebc;
    }
    li {
        margin-bottom: 2.66vw;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        border-radius: 2.5px;
        background: #fff;
        padding: 2.66vw;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__pic {
        margin-right: 3.93vw;
        width: 18.93vw;
        & img {
            margin: 0 auto;
        }
        &__1 {
            width: 78%;
        }
        &__5 {
            width: 69%;
        }
        &__7 {
            width: 83%;
        }
    }
    &__text {
        width: 66vw;
        &-title {
            font-weight: bold;
            font-size: 1.65rem;
            span {
                padding: 0.5vw 1vw;
                background: #fb7873;
                font-size: 1.5rem;
            }
        }
        p{
            font-size: 1.5rem;
            color: #777;
        }
    }
    .p-ctaArea__lead{
        line-height: 1.5;
    }
}
.p-overlay{
    display: none;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}