/*---------------------------------------
component-marker
----------------------------------------*/
.c-marker { //blue ver.
    position: relative;
    padding-left: 5.5vw;

    &:before {
        content: "";
        width: 4px;
        height: 1.3em;
        display: block;
        background-color: #3D62AC;
        position: absolute;
        left: 3%;
        top: 26%;
    }
}
