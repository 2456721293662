/*---------------------------------------
page-icon
----------------------------------------*/
.p-facilityMarker {
    & li { //ul
        width: 12vw;
        display: inline-block;

        & img {
            max-width: 100%;
            height: auto;

        }
    }
}
