/*---------------------------------------
layout-header
----------------------------------------*/
.l-header {
    width: 100%;
    height: 16vw;
    display: flex;
    justify-content: flex-end;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    position: relative;

    & a {
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    &__logo { //ロゴ
        width: 40vw;
        display: flex;
        align-items: center;
        margin-right: auto;
        padding: 4.93vw 0 6vw;

        & img {
            width: 100%;
            height: auto;
        }
    }

    &__tel { //お問い合わせボタン
        color: #5F9113;
        text-align: center;
        display: block;
        border-left: 1px solid $lineGrey;
        border-right: 1px solid $lineGrey;
        padding: 0.66vw 1.33vw 0.66vw 5.73vw;
        background: url(/assets/sp/images/icon_header_tel.png) 7% center no-repeat;
        background-size: 3.94vw;
        line-height: 1.4;
        font-size: 1rem;
        margin: 2.66vw 0;

        &.hover + div { //お問い合わせボタンクリックでモーダル画面表示
            display: block;
            animation: tel-number-show 0.3s linear 0s;
        }
    }

    &__step { //資料請求・見学予約リストボタン
        color: #6D4E23;
        line-height: 1.4;
        font-size: 1rem;
        display: block;
        padding: 0.66vw 1.33vw 0.66vw 6.66vw;
        background: url(/assets/sp/images/icon_header_step.png) 7% center no-repeat;
        background-size: 3.94vw;
        margin: 2.66vw 0;
        position: relative;
    }

    &__stepCounter { //資料請求・見学予約リストカウンター
        width: 5.33vw;
        height: 5.33vw;
        font-size: 1.2rem;
        color: #fff;
        line-height: 1.6;
        text-align: center;
        background-color: #FB7873;
        border-radius: 50%;
        position: absolute;
        left: 3%;
        top: 50%;
    }

    &__menu { //ハンバーガーメニューボタン
        width: 15vw;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background: #DEF2FE;
        font-size: 1rem;
        padding: 2.07vw 0;
        position: relative;
        color: #3D62AC;
        z-index: 120;

        &.active span { //ハンバーガーメニューボタン active時
            &:nth-of-type(1) {
                transform: translateY(1.06vw) rotate(-45deg);
            }

            &:nth-of-type(2) {
                opacity: 0;
            }

            &:nth-of-type(3) {
                transform: translateY(-3.2vw) rotate(45deg);
            }
        }

        & span { //ハンバーガーメニューボタン アイコン 非active時
            display: inline-block;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            width: 7.2vw;
            height: 1vw;
            /* stylelint-disable-next-line */
            margin: 0 auto;
            background-color: #3D62AC;
            transition: all .5s;

            &:nth-of-type(1) {
                top: 4.07vw;
            }

            &:nth-of-type(2) {
                top: 6.07vw;
            }

            &:nth-of-type(3) {
                top: 8.07vw;
            }
        }
    }
}

.headerNonePadding { //bodyに付与
    padding-top: 0;
}

// 資料請求・見学予約リスト メッセージ アニメーション
@keyframes dialog {
    0% {
        opacity: 0;
        transform: translate(0, -10px);
    }
    10% {
        opacity: 1;
        transform: translate(0, 0);
    }
    90% {
        opacity: 1;
        transform: translate(0, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, -10px);
    }
}

.l-header__reserve--dialog:before,
.l-header__reserve--dialog:after,
.l-header__reserve--requestError:before,
.l-header__reserve--requestError:after,
.l-header__request--dialog:before,
.l-header__request--dialog:after,
.l-header__request--requestError:before,
.l-header__request--requestError:after {
    opacity: 1.0;
    animation: dialog 2.5s ease-out 0s 1 alternate forwards;
    font-size: 1.5rem;
    text-align: center;
    pointer-events: none;
}

.l-header__reserve--dialog:before,
.l-header__request--dialog:before {
    content: "";
    width: 2.66vw;
    height: 1.33vw;
    display: block;
    background: url(/assets/sp/images/tooltip_top_arrow.png) no-repeat 0 0;
    background-size: 2.66vw 1.33vw;
    position: absolute;
    right: 15%;
    top: 92%;
}

.l-header__reserve--dialog:after {
    content: "見学予約を\A追加しました。";
}

.l-header__request--dialog:after {
    content: "資料請求を\A追加しました。";
}

.l-header__reserve--dialog:after,
.l-header__request--dialog:after {
    padding: 8px 10px;
    color: #FFF;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 100%;
    right: 5%;
    line-height: 1.2;
    white-space: pre;
}

.l-header__reserve--requestError:before,
.l-header__request--requestError:before {
    content: "";
    width: 2.66vw;
    height: 1.33vw;
    display: block;
    background: url(/assets/sp/images/tooltip_top_arrow_error.png) no-repeat 0 0;
    background-size: 2.66vw 1.33vw;
    position: absolute;
    top: 92%;
}

.l-header__reserve--requestError:after,
.l-header__request--requestError:after {
    content: "リストに追加できるのは\A 10件までとなります。";
    padding: 8px 10px;
    color: #FFF;
    border-radius: 5px;
    background: rgba(217, 0, 0, 0.9);
    position: absolute;
    top: 100%;
    right: 5%;
    line-height: 1.2;
    white-space: pre;
}

@media screen and (orientation: landscape) {
    /* 横向きの場合のスタイル */
    .l-header__reserve--dialog:before,
    .l-header__request--dialog:before {
        top: 85%;
    }
    .l-header__reserve--dialog:after,
    .l-header__request--dialog:after {
        top: 93%;
    }
    .l-header__reserve--requestError:before,
    .l-header__request--requestError:before {
        top: 85%;
    }
    .l-header__reserve--requestError:after,
    .l-header__request--requestError:after {
        top: 93%;
    }
}

@media screen and (min-width: 667px) and (max-width: 735px) {
    /* 横向きの場合のスタイル */
    .l-header__stepCounter {
        line-height: 1.8;
    }
}

@media screen and (min-width: 736px) {
    /* 横向きの場合のスタイル */
    .l-header__stepCounter {
        line-height: 1.8;
    }
}

// お問い合わせ モーダル画面表示アニメーション
@keyframes tel-number-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.l-headerSimple {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 15vw;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    padding: 0 2.66vw 0;
    &__logo { //ロゴ
        width: 40vw;
        & img {
            width: 100%;
        }
    }
}