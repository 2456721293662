/*---------------------------------------
施設検索結果一覧・詳細用FAQ
----------------------------------------*/
.p-searchFaqArea {
    & li {
        border-top: 1px solid #AAAAAA;

        &:last-child {
            border-bottom: 1px solid #AAAAAA;
        }
    }

    & .p-faqList {
        &__question {
            padding: 2.66vw 9.06vw 2.5vw 2.66vw;
            background: url(/assets/sp/images/icon_arrow_down.png) right 2.66vw center no-repeat;
            background-size: 3.46vw auto;

            &.is-active {
                background: url(/assets/sp/images/icon_arrow_up.png) right 2.66vw center no-repeat;
                background-size: 3.46vw auto;
            }
        }

        &__category {
            background: #DEF2FE;
            font-size: 1.5rem;
            padding: 0.5vw 1.5vw;
        }

        &__answer {
            display: none;
            background: #EEEEEE;
            padding: 2.66vw 2.66vw 4vw;

            & p {
                margin-bottom: 5.33vw;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}