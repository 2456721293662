/*---------------------------------------
component-form
----------------------------------------*/
.c-select {
    padding: 2.4vw 2.66vw;
    background: url(/assets/sp/images/select_arrow.png) 101% center no-repeat, #fff;
    background-size: contain;
    border: 1px solid $lineGrey;
    border-radius: 0.2em;
    -webkit-appearance: none;
    appearance: none;
    color: #333;
}

.c-input {
    padding: 2.4vw 2.66vw;
    border: 1px solid $lineGrey;
    border-radius: 0.2em;
    -webkit-appearance: none;
    appearance: none;
}

.c-input::placeholder {
    color: $lineGrey;
}

.c-checkBox,
.c-radioButton {
    display: none;
}

.c-cBLabel { //labelに付与
    padding: .4vw 2.88vw 1vw;
    position: relative;
    background: url('/assets/sp/images/checkbox.png') 0% no-repeat;
    background-size: contain;

    &--readonly {
        padding: .4vw 2.88vw 1vw;
        position: relative;
        background: url('/assets/sp/images/checkbox_checked.png') 0% no-repeat;
        background-size: contain;
    }
}

.c-checkBox:checked + .c-cBLabel { //<label>に付与
    background: url('/assets/sp/images/checkbox_checked.png') 0% no-repeat;
    background-size: contain;
}

.c-radioLabel { //labelに付与
    position: relative;
    padding: .4vw 2.88vw 1vw;
    background: url('/assets/sp/images/radio_basic.png') 0% no-repeat;
    background-size: 6.9vw;
}

.c-radioButton:checked + .c-radioLabel { //<label>に付与
    background: url('/assets/sp/images/radio_checked.png') 0% no-repeat;
    background-size: 6.9vw;
}

.c-textarea {
    padding: 2.4vw 2.66vw;
    border: 1px solid #aaa;
    border-radius: .2em;
    width: 100%;
    height: 55vw;
    word-break: break-all;
    word-wrap: break-word;
}

.c-textarea::placeholder {
    color: $lineGrey;
}
