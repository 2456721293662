/*---------------------------------------
project-headermodal
----------------------------------------*/
.p-headermodal {
    display: none;
    position: relative;
    z-index: 100;

    &__content {
        width: 100%;
        background: #fff;
        text-align: center;
        padding: 3.33vw 2.66vw;
        position: absolute;
        top: 0;
    }

    &__telNumber {
        width: 100%;
        background: #fff;
        text-align: center;
        padding: 3.33vw 2.66vw;
        position: fixed;
        top: 16vw;
        left: 0;

        & a {
            display: block;
            text-decoration: none;
            font-weight: bold;
            font-size: 2.3rem;
            color: $mainGreen;
        }

        & span {
            font-size: 1.5rem;
        }
    }

    &__step {
        background: rgba(61, 98, 172, .93);
        overflow-y: scroll;
    }

    &__stepNone {
        color: #fff;
        margin-bottom: 6.13vw;
    }

    &__submitButton {
        width: 100%;
        font-size: 1.85rem;
        line-height: 1.45;
        margin-bottom: 5.32vw;
    }

    &__stepClose {
        width: 18vw;
        margin: 0 auto 18.66vw;
        padding: 1.5vw;
        background: #fff;
        color: #333;
    }
}

