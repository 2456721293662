/*---------------------------------------
project-menu
----------------------------------------*/
.p-menu {
    border-top: 1px solid #aaa;
    width: 82.66vw;
    height: 100%;
    position: fixed;
    right: 0;
    z-index: 120;
    transform: translate(82.66vw);
    transition: all .5s;
    overflow-y: scroll;
    padding-bottom:15vw;

    &.open {
        transform: translateZ(0);
    }

    &__background {
        width: 82.66vw;
        height: 100vh;
        background: #fff;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 110;
        transform: translate(82.66vw);
        transition: all .5s;

        &.open {
            transform: translateZ(0);
        }
    }

    &__overlay {
        content: "";
        display: block;
        width: 0;
        height: 0;
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        opacity: 0;
        transition: opacity .5s;

        &.open {
            width: 100%;
            height: 100vh;
            opacity: 1;
        }
    }

    &__ctaCall {
        padding: 2.66vw 4vw;
        background: $bgBeige;
        text-align: center;

        &__text {
            padding: 0 0 2.66vw 0;
            font-size: $memoFont;
            text-align: left;
        }
    }

    &__ctaAreaButton {
        font-size: $memoFont;

        & span {
            font-size: $mainFont;
        }
    }

    &__ctaCallMemo {
        font-size: 0.9rem;
    }
}

@media screen and (orientation: landscape) {
    /* 横向きの場合のスタイル */
    .p-menu {
        height: 70vh;
    }
}