@charset "utf-8";
/*---------------------------------------
layout-cont
----------------------------------------*/
.cont {
    padding: 2.66%;

    &--blue {
        background: $bgBlue;
    }
}
