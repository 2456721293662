.c-tag { //ul

    & li {
        margin: 0 1.86vw 2.66vw 0;
        padding: 1.33vw;
        border: 1px solid $lineGrey;
        display: inline-block;
        color: $textGrey;

        &.is-selected {
            background: #D4D4D4;
            border: 1px solid #D4D4D4;
        }
    }

    & a {
        text-decoration: none;
        color: $textGrey;
    }
}

.c-tagFacility {
    padding: 1.5vw .5vw;
    margin-right: -.3vw;
    display: inline-block;
    line-height: 1;
    vertical-align: top;
    background: $bgBlue;
    font-size: 1.2rem;

    &--q {
        padding-right: 2.5rem;
        background: url(/assets/sp/images/icon_question.png) 97% no-repeat, #DEF2FE;
        background-size: 4.4vw;
        text-decoration: none;
        color: #333;
    }
}

.c-taglabel:after {
    width: 14.66vw;
    height: 5.33vw;
    position: absolute;
    top: -1.33vw;
    left: 1.06vw;
    display: block;
    content: "";
}

.c-tagPr:after { //
    background: url(/assets/sp/images/list/label_pr.png) no-repeat;
    background-size: 100%;
}

.c-tagNew:after {
    background: url(/assets/sp/images/list/label_new.png) no-repeat;
    background-size: 100%;
}

.c-tagBrowse:after {
    background: url(/assets/sp/images/list/label_browse.png) no-repeat;
    background-size: 100%;
}
