/*---------------------------------------
project-textbox
----------------------------------------*/
.p-textbox { //上下左右余白
    padding: 2.66vw;

    &--inner { //上下のみ余白
        padding: 2.66vw 0;
    }

    &--beige { //ベージュ背景のBOX
        padding: 2.66vw;
        background-color: $bgBeige;
    }

    &--blue { //水色背景のBOX
        padding: 2.66vw;
        background-color: $bgBlue;
    }

    &--gray { //グレー背景のBOX
        padding: 2.66vw;
        background-color: $bgGrey;
    }

    &--campaign { //キャンペーン用
        & div {
            padding: 2.66vw;
            background-color: $bgGrey;
        }

        &__pic {
            max-width: 100%;
            height: auto;
        }
    }

    &--campaign iframe { //キャンペーン用
        padding: 2.66vw;
        display: block;
        margin: 0 auto;

    }
}
