/*---------------------------------------
project-breadcrumbs
----------------------------------------*/
.p-breadcrumbs {
    background: #eee;
    font-size: 1.4rem;
    text-decoration: none;

    & ul {
        margin: 0 auto;
        width: 95vw;
        padding: 2.66vw 0;
        overflow-x: auto;
        white-space: nowrap;
    }

    & li {
        display: inline;
    }

    &__inner {
        overflow-x: auto;
        white-space: nowrap;
    }

    &__item {
        padding-right: 0.4vw;
        text-decoration: none;
    }
}
