@charset "utf-8";
/*---------------------------------------
project-cta(Call to Action)
----------------------------------------*/
.p-ctaArea {
    padding: 2.66vw;
    text-align: center;

    &__lead {
        margin-bottom: 1.33vw;
        color: #83C126;
        font-weight: bold;
        font-size: 1.5rem;
    }

    &__lead--black {
        margin-bottom: 4vw;
        color: #000;
        font-size: $headlineFont;
        font-weight: bold;
        line-height: 1.5;
    }

    &__button {
        width: 100%;
        line-height: 1.35;
        position: relative;

        &--s {
            width: 42.67vw;
            font-weight: bold;
            line-height: 1.35;
        }

        &__normalText {
            font-weight: normal;
            font-size: $mainFont;
        }

        &__smallText {
            font-size: 1.5rem;
        }
    }

    &__fd {
        padding: 2.44vw 0 1.7vw;
        margin-bottom: 1.73vw;
    }

    &__submit {
        padding: 3.7vw 0 2.44vw;
        font-size: $mainFont;
    }

    &__addList {
        padding: 3.7vw 0 2.44vw;
        font-size: 1.5rem;
        font-weight: normal;
    }

    &__strong {
        font-size: 2.3rem;
        font-weight: bold;
        vertical-align: middle;
        padding-bottom: .4rem;
        display: inline-block;
    }

    &--full {
        padding: 4vw 5.33vw 2.26vw;
        background: #f9f6e8;
        text-align: center;
    }
}

.p-ctaCall {
    margin: 5.32vw 2.66vw;
    padding: 2.66vw;
    background: $bgBeige;
    text-align: center;

    &__text {
        padding: 0 0 4vw 27vw;
        text-align: left;
        background: url('/assets/sp/images/list/thmb_counselor.png') left top no-repeat;
        background-size: 24.77% auto;
        @supports (background-image: url('/assets/sp/images/list/thmb_counselor.webp')) {
            background-image: url('/assets/sp/images/list/thmb_counselor.webp');
        }
    }
}
