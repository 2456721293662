/*---------------------------------------
component-cardbox
----------------------------------------*/
.c-cardbox {
    background: #fff;
    padding: 2.66vw;
    border-bottom: 3px solid #AAA;
    display: block;

    &--arrow { //矢印付きのcard
        background: url(/assets/sp/images/icon_arrow_right.png) 97% center no-repeat, #fff;
        color: inherit;
    }

    &--s { //テキストのみの矢印付きのcard
        padding: 2.66vw 6vw 2.66vw 3.2vw;
        border: 1px solid $lineGrey;
        border-bottom: 3px solid $lineGrey;
        display: block;
        background: url(/assets/sp/images/icon_arrow_right.png) 97% center no-repeat, #fff;
        background-size: 2.5% auto;
        color: inherit;
        text-decoration: none;
    }
}
