@charset "utf-8";
/*---------------------------------------
Base
----------------------------------------*/
/*---color---*/
$mainBlue: #3D62AC; //メイン青
$mainGreen: #83C126; //メイン黄緑
$mainButton: #FB7873; //submit用メインボタン
$mainButtonShadow: #B9332D; //submit用メインボタンのシャドウ
$mainLightButton: #FCBEBC; //メインの薄ピンク
$lineGrey: #AAA; //border用グレー
$buttonGrey: #D4D4D4; //ボタン用グレー
$buttonGreyShadow: #AAA; //グレーのボタンのシャドウ
$bgBlue: #DEF2FE; //背景用水色
$bgGreen: #E9F8D3; //背景用黄緑
$bgPink: #FFEEEE; //背景用ピンク
$bgGrey: #eee; //背景用グレー
$bgBeige: #F9F6E8; //背景用ベージュ

$textBlack: #333;
$textRed: #F81504; //赤文字
$textGrey: #777; //グレー文字

/*---fontsize---*/
$headlineFont: 1.85rem;
$mainFont: 1.65rem;
$memoFont: 1.2rem;

/*---link---*/
a {
    color: #003BEB;
}

/*a:visited {
	color:initial;
}*/
th, td {
    vertical-align: middle;
}

caption, th, td {
    text-align: left;
}

/*---headline---*/
h1 {
    font-weight: bold;
    font-size: $headlineFont;
}

h2 {
    font-weight: bold;
    font-size: $mainFont;
}

/*---記事コンテンツ用---*/
.faceBold {
    font-weight: bold;
}

.faceRed {
    color: #FF4C00;
    font-weight: normal;
}

.faceBoldRed {
    color: #FF4C00;
    font-weight: bold;
}

.faceBoldSiteColor {
    color: #2A65B7;
    font-weight: bold;
}

.textMarker {
    margin: 0 0.3em;
    padding: 0 0.3em;
    background-color: #FFFFDD;
}

.backColor {
    padding: 4vw 5.33vw;
    background-color: #F5F5F5;
}

.fontNote {
    color: #777;
}

.fontAttention {
    margin-left: 1em;
    color: #777;
    font-size: 82%;
    text-indent: -1em;
    display: block;
}

cite {
    margin-bottom: 15px;
    color: #A3A3A3;
    text-align: right;
    font-style: normal;
    display: block;
}

#pageNews section > div table,
#pageNews section > div table th,
#pageNews section > div table td,
#pageInfo section > div table,
#pageInfo section > div table th,
#pageInfo section > div table td {
    padding: 2vw;
    border: 1px solid #CCC;
}

#pageNews section > div.scrollTable table,
#pageInfo section > div.scrollTable table {
    border: none;
}


#pageNews section > div.scrollTable table th,
#pageNews section > div.scrollTable table td,
#pageInfo section > div.scrollTable table th,
#pageInfo section > div.scrollTable table td
#pageGuide section > div.scrollTable table th,
#pageGuide section > div.scrollTable table td,
#pageGuide section > div.scrollTable table tbody th,
#pageGuide section > div.scrollTable table tbody td  {
    border-left: 1px solid #CCC;
    border-top: 1px solid #CCC;
    border-bottom: none;
    border-right: none;
}

#pageGuide section > div.scrollTable table tbody th[rowspan],
#pageGuide section > div.scrollTable table tbody th[colspan] {
    border-left: none;
}

#pageNews section > div.scrollTable table th:last-child,
#pageNews section > div.scrollTable table td:last-child,
#pageInfo section > div.scrollTable table th:last-child,
#pageInfo section > div.scrollTable table td:last-child {
    border-right: 1px solid #CCC;
}

#pageNews section > div.scrollTable tbody tr:last-child th,
#pageNews section > div.scrollTable tbody tr:last-child td {
    border-bottom: 1px solid #CCC;
}


//見出しが2列以上のtable用
.scrollTable {
    padding: 0 0 20px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    & .sticky {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 1;
        border-left: 1px solid #CCC;
        border-right: 1px solid #CCC!important;
        & + th, + td {
            border-left: none!important;
        }
    }
    & td.sticky {
        background: #FFF;
    }

    & table {
        border-collapse: separate;
        margin: 0;
        display: block;
        width: 100%;
        overflow-x: scroll;
        white-space: nowrap;
        border: 1px solid #ccc;
        padding: 0!important;

        & thead {
            border-bottom: 1px solid #BBB;

            & th {
                width: 100%;
                display: table-cell;
                border-right: 1px solid #CCC;
                background-color: #EBEFF7;
                padding: 10px 10px 8px;
                font-weight: bold;
                text-align: center;

                &:last-child {
                    border-right: none;
                }
            }
        }

        & tbody {
            & th {
                width: 100%;
                display: table-cell;
                border-top: 1px solid #CCC;
                border-bottom: 1px solid #BBB;
                border-right: 1px solid #CCC;
                background-color: #EBEFF7;
                padding: 10px 10px 8px;
                font-weight: bold;
                text-align: center;

                &:last-child {
                    border-right: none;
                }
            }

            & tr {
                & td {
                    width: 100%;
                    border-top: 1px solid #CCC;
                    border-bottom: 1px solid #BBB;
                    border-right: 1px solid #CCC;
                    display: table-cell;
                    padding: 10px 10px 8px;
                    text-align: center;

                    &:last-child {
                        border-right: none;
                    }
                }

                &:last-child {
                    & th {
                        border-bottom: none;
                    }

                    & td {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

#pageNews section > div table,
#pageInfo section > div table {
    margin: 10px auto;
    font-size: 94%;
}

#pageNews section > div table th,
#pageInfo section > div table th {
    background-color: #F6F6F6;
    font-weight: bold;
}

#pageNews section span.tag img[width="1"],
#pageInfo section span.tag img[width="1"] {
    width: 1px;
    height: 1px;
    display: inline;
}

.authority {
    margin-bottom: 4vw;
    padding: 6.13vw;
    border-radius: 0 20px 0 20px;
    background-color: #EEE;
}

.authority blockquote p:before {
    content: url(/assets/sp/images/icon_quotation.gif);
    width: 57px;
    height: 54px;
    float: left;
    position: relative;
    top: 5px;
    display: block;
}

.authority cite {
    margin-bottom: 0;
}

.layoutPattern01 {
    height: auto;
    overflow: hidden;
    clear: both;
}

article .chapterArea .layoutPattern01 img {
    margin: 0 auto 4vw;
    width: 23%;
    float: none;
    display: block;
}

#contents article iframe { //YouTube用
    width: 100%;
    height: auto;
}

/*---------------------------------------
#記事コンテンツ用誘導ボタン
----------------------------------------*/
#contents {
    & .pg-guideButtonWrap { //ボタン外側の上下padding用
        padding: 8vw 0;

        &--cta {
            padding: 8vw 0;
        }
    }

    & .pg-guideButton {
        &--search { //検索導線
            margin: 0 auto;
            display: block;
            width: 80vw;
            border-radius: 4px;
            background: #FCBEBC;
            box-shadow: 0 4px 0 #E88985;
            padding: 3.33vw;
            text-align: center;
            text-decoration: none;
            color: #333333;
        }

        &--news { //記事導線
            margin: 0 auto;
            display: block;
            width: 80vw;
            border-radius: 4px;
            background: #D4D4D4;
            box-shadow: 0 4px 0 #AAAAAA;
            padding: 3.33vw;
            text-align: center;
            text-decoration: none;
            color: #333333;
        }

        &--cta { //相談室に相談
            margin: 0 auto 1.6vw;
            display: block;
            width: 89.3vw;
            border-radius: 4px;
            background: #83C126;
            box-shadow: 0 4px 0 #456713;
            padding: 1.33vw 0 1.86vw;
            text-align: center;
            text-decoration: none;
            color: #ffffff;

            &__lead {
                font-weight: bold;
                color: #ffffff;
            }

            &__phone {
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 1.5;
            }

            &__phoneNumber {
                margin-right: 1.4vw;
                font-size: 2.3rem;
                font-weight: bold;
            }

            &__phoneFree {
                font-size: 1.5rem;
                font-weight: bold;
            }

            &__companyPC {
                font-size: 63%;
                color: #777777;
            }

            &__companySP {
                text-align: center;
                font-size: 1.2rem;
                color: #777777;
            }
            /* stylelint-disable-next-line */
            &__companyPC {
                display: none;
            }
        }

        &--textLink { //テキストリンク
            margin: 8vw auto;
            display: block;
            width: 81vw;
            box-sizing: border-box;
            padding: 3vw 1.8vw;
            background: #FFF2E5;
            text-align: center;
            line-height: 1.5;
            & strong {
                font-weight: bold;
            }
        }

        &--imagesLink { //画像つきリンク
            margin: 8vw 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border: 1px solid #CCCCCC;
            border-radius: 4px;
            box-shadow: 0 5px 0 #AAAAAA;
            padding: 2.7vw;
            text-decoration: none;
            transition: all 0.3s;
            &:hover {
                filter: alpha(opacity=80);
                opacity: 0.8;
            }
            & img {
                &.thumbnail {
                    margin: 0 2.7vw 0 0;
                    width: 21.3vw;
                    height: auto;
                }
            }
            & strong {
                font-weight: bold;
                text-decoration: underline;
                width: 64vw;
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                font-size: 1.5rem;
            }
            & p {
                &.pg-guideButton--imagesLink__textSP {
                    margin: 1vw 0 0 0;
                    text-decoration: none;
                    color: #333333;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-size: 1.5rem;
                }
                &.pg-guideButton--imagesLink__textPC {
                    display: none;
                }
            }
        }
    }
}