/*---------------------------------------
project-alert
----------------------------------------*/
.p-alert {
    display: none;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .7);
    position: absolute;
    top: 0;
    z-index: 130;

    &__item {
        position: absolute;
        top: 30vw;
        right: 0;
        left: 0;
        z-index: 6;
    }

    &__itemHeadline {
        display: flex;
    }

    &__itemClose {
        font-size: 3rem;
        line-height: 1;
        margin-left: auto;
    }

    &__itemMain {
        background: #fff;
        padding: 4vw 2.66vw;
    }

    &__itemButtonList {
        display: flex;
        justify-content: center;

        & li:first-child {
            margin-right: 5.32vw;
        }
    }

    &__itemButton {
        padding: 3.1vw 0;
        background: #fcbebc;
        box-shadow: 0 3px 0 #e88985;
        width: 30.66vw;
    }
}
