/*---------------------------------------
project-button
----------------------------------------*/
/*--button--*/
.p-moreButton {
    padding: 2.66vw 6vw 2.66vw 1.33vw;
    background: url('/assets/sp/images/icon_open_plus.png') 97% center no-repeat, #fff;
    background-size: 3.71% auto;
    border: 1px solid #aaa;
    border-bottom: 3px solid #aaa;
    color: #777;
    text-align: center;

    &.is-open {
        background: url('/assets/sp/images/icon_close_minus.png') 97% center no-repeat, #fff;
        background-size: 3.71% auto;
    }
}
