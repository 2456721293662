/*---------------------------------------
component-thumb
----------------------------------------*/
.c-thumbnail {
    width: 66.66vw;
    margin: auto;

    &--l {
        width: 80vw;
        height: auto;
        margin: auto;
    }

    &--m {
        width: 59.73vw;
        height: auto;
    }

    &--s {
        width: 28vw;
        height: auto;
    }
}
