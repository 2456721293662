@charset "utf-8";
/*---------------------------------------
layout-main
----------------------------------------*/
.l-main--step {
    padding-bottom: 6.66vw;
    margin-bottom: 3.7vw;
    border-bottom: 1px solid #aaa;
}

.l-main--float { //listページ floating用
    margin-bottom: 18.13vw;
}
