@charset "utf-8";
/*---------------------------------------
project-list
----------------------------------------*/
.p-list { //ulにつける
    border-top: 1px solid $lineGrey;

    /*----ノーマルのリスト----*/
    & > li {
        border-bottom: 1px solid $lineGrey;
    }

    &__anchor { //<a>やliの内部につける
        display: block;
        padding: 3.4vw 9vw 3.1vw 2.66vw;
        text-decoration: none;
        color: inherit;
    }

    /*----灰色のリスト----*/
    &__item--gray { //liにつける
        background: #eee;
        border-bottom: 1px solid #8EA6D2;
        font-size: $headlineFont;
    }

    /*----矢印付きのリスト----*/
    &--arrow > li { //ulにつける
        border-bottom: 1px solid $lineGrey;
        background: url(/assets/sp/images/icon_arrow_right.png) 97% center no-repeat #FFF;
        background-size: 2.1vw auto;
    }

    &--arrowBlueline > li { //ulにつける
        border-bottom: 1px solid #8EA6D2;
        background: url(/assets/sp/images/icon_arrow_right.png) 97% center no-repeat #FFF;
        background-size: 2.1vw auto;
    }
}
