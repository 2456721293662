/*---------------------------------------
component-button
----------------------------------------*/
.c-submitButton {
    padding: 3.1vw;
    background: $mainButton;
    font-size: $headlineFont;
    color: #fff;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    border-radius: .2rem;
    border: none;
    box-shadow: 0 .4rem 0 $mainButtonShadow;
    text-decoration: none;

    &--l {
        padding: 3.1vw;
        background: $mainButton;
        color: #fff;
        font-weight: bold;
        text-align: center;
        display: block;
        border-radius: .2rem;
        border: none;
        box-shadow: 0 .4rem 0 $mainButtonShadow;
    }
}

.c-addButton {
    padding: 3.1vw;
    background: $buttonGrey;
    font-size: $headlineFont;
    color: $textBlack;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    border-radius: 0.2rem;
    border: none;
    box-shadow: 0 .4rem 0 $buttonGreyShadow;

    &--l {
        padding: 3.1vw;
        background: $buttonGrey;
        font-size: $headlineFont;
        color: $textBlack;
        font-weight: normal;
        text-align: center;
        display: block;
        border-radius: 0.2rem;
        border: none;
        box-shadow: 0 .4rem 0 $buttonGreyShadow;
    }

}

.c-fdButton {
    background: $mainGreen;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    display: block;
    border-radius: 0.2rem;
    border: none;
    box-shadow: 0 .4rem 0 #456713;
    text-decoration: none;
}

.c-anchorButton { //<a>ボタン用、色なし
    //padding:3.1vw 0;
    color: #333;
    text-align: center;
    display: inline-block;
    border-radius: 0.2rem;
    border: none;
    text-decoration: none;
}

.c-requestButton { //らくらく資料請求用ボタン
    padding: 6vw 0;
    background: $mainButton;
    color: #fff;
    line-height: 1.35;
    font-size: 2.3rem;
    text-decoration: none;
    display: block;
    border-radius: .2rem;
    border: none;
    box-shadow: 0 .4rem 0 $mainButtonShadow;
}

.c-shineButton {
    position: relative;
    overflow: hidden;
    &:after {
        content: "";
        display: block;
        position: absolute;
        left: -8vw;
        top: -8vw;
        width: 26vw;
        height: 35vw;
        transform: rotate(30deg);
        background-image: linear-gradient(to left, rgba(#fff, 0) 0%, rgba(#fff, 0.6) 50%, rgba(#fff, 0) 100%);
        animation: shiny 4s infinite linear;
    }
}
.c-lineButton {
    background: #06C755;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    display: block;
    border-radius: 0.2rem;
    border: none;
    box-shadow: 0 .4rem 0 #456713;
    text-decoration: none;
}

@keyframes shiny {
    0% {
        left: -10%;
    }
    30% {
        left: 110%;
    }
    100% {
        left: 110%;
    }
}