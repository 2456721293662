/*---------------------------------------
project-button
----------------------------------------*/
.p-contentsButton {
    padding: 5.7vw 5.3vw 5.7vw 15.46vw;
    position: relative;
    border: 5px solid $bgBlue;
    background: #fff;
    display: block;
    color: inherit;
    font-weight: bold;
    text-decoration: none;
    box-shadow: 0 2px 0 #A4B5CE;

    &::before {
        content: '';
        width: 10.66vw;
        height: 10.66vw;
        margin-top: -5.33vw;
        position: absolute;
        top: 50%;
        left: 1rem;
    }

    &--type::before {
        background: url(/assets/sp/images/icon_contentsButtonType.png) 0 0;
        background-size: contain;
    }

    &--select::before {
        background: url(/assets/sp/images/icon_contentsButtonSelect.png) 0 0;
        background-size: contain;
    }

    &--glossary::before {
        background: url(/assets/sp/images/icon_contentsButtonGlossary.png) 0 0;
        background-size: contain;
    }

    &--area::before {
        background: url(/assets/sp/images/icon_contentsButtonArea.png) 0 0;
        background-size: contain;
    }

    &--route::before {
        background: url(/assets/sp/images/icon_contentsButtonRoute.png) 0 0;
        background-size: contain;
    }

    &--zip::before {
        background: url(/assets/sp/images/icon_contentsButtonZip.png) 0 0;
        background-size: contain;
    }
}

.p-contentsBanner {
    padding: 5vw 5.3vw 5vw 16.8vw;
    position: relative;
    border: 1px solid $lineGrey;
    display: block;
    color: #777;
    text-decoration: none;
    font-size: $headlineFont;

    &::before {
        content: '';
        width: 10.66vw;
        height: 10.66vw;
        margin-top: -5.33vw;
        position: absolute;
        top: 50%;
        left: 1.5rem;
    }

    &--guide {
        background: url(/assets/sp/images/contentsBannerGuide.png) center center no-repeat;
        background-size: cover;
    }

    &--guide::before {
        background: url(/assets/sp/images/icon_contentsBannerGuide.png) 0 0 no-repeat;
        background-size: contain;
    }

    &--news {
        background: url(/assets/sp/images/contentsBannerNews.png) center center no-repeat;
        background-size: cover;
        margin-bottom: 7.03vw;
    }

    &--news::before {
        background: url(/assets/sp/images/icon_contentsBannerNews.png) 0 0 no-repeat;
        background-size: contain;
    }
}
