@charset "utf-8";
/*---------------------------------------
project-buttonarea
----------------------------------------*/
.p-buttonArea {
    display: flex;
    justify-content: space-between;

    &.is-hidden {
        display: none;
    }

    &--num {
        background: rgba(0, 0, 0, .7);
        padding: 4.66vw 2.66vw 5.2vw;
    }

    &--page {
        background: rgba(0, 0, 0, .7);
        padding: 3.06vw 2.66vw 3.6vw;
    }

    &--f {
        background: rgba(0, 0, 0, .7);
        padding: 2.13vw 2.66vw 2.66vw;
        width: 100%;
    }

    &__addCondition {
        width: 36vw;

        &--s {
            letter-spacing: -.1rem;
            padding: 1.5vw;
            width: 29.66vw;
        }
    }

    &--addConditionFixed {
        background: rgba(0, 0, 0, .7);
        position: fixed;
        right: 2.5vw;
        bottom: 17vw;
        width: 18.66vw;
        height: 18.66vw;
        color: #fff;
        font-weight: bold;
        border: none;
        border-radius: 50%;
    }

    &--tel {
        align-items: center;

        &__lead {
            color: #ffffff;
            font-size: 1.4rem;
            font-weight: bold;
        }

        &__fdButton {
            width: 38vw;
            background: url(/assets/sp/images/icon_tel.png) 5% center no-repeat #83c126;
            background-size: 8vw auto;
            padding: 2vw 0 1.7vw 5vw;
            color: #fff;
            font-size: 1.6rem;
            font-weight: bold;
            text-align: center;
            display: block;
            border-radius: .2rem;
            border: none;
            box-shadow: 0 0.4rem 0 #456713;
            text-decoration: none;
            line-height: 1;

            & span {
                font-size: 1.4rem;
                font-weight: normal;
            }
        }

        &__requestButton {
            width: 46.66vw;
            display: block;
            background: #fb7873;
            padding: 2.4vw 0;
            color: #fff;
            text-decoration: none;
            border-radius: .2rem;
            border: none;
            box-shadow: 0 0.4rem 0 #b9332d;
        }
    }

    &__search {
        width: 56vw;

        &--s {
            letter-spacing: -.1rem;
            padding: 1.5vw;
            width: 38.66vw;
        }

        &--l {
            letter-spacing: -.1rem;
            padding: 1.5vw;
            width: 71.33vw;
        }

        &--page {
            width: 61.33vw;
            margin: 0 auto;
        }
    }

    &__num {
        margin-left: auto;
        padding-top: 2.66vw;
        font-size: $headlineFont;
        font-weight: 600;
        color: #fff;
        width: 21vw;
        line-height: 1;
        /* stylelint-disable-next-line */
        font-weight: 600;
        text-align: center;
    }

    &__back {
        background: url(/assets/sp/images/icon_arrow_left.png) 10% center no-repeat #D4D4D4;
        background-size: 2.1vw auto;
        padding: 1.24vw 3.2vw 1.55vw 6.13vw;
        font-size: 1.85rem;
        border-radius: .2rem;
        border: none;
        box-shadow: 0 0.4rem 0 #AAAAAA;
        color: #333;
    }
}
