@charset "utf-8";
/*---------------------------------------
layout-fixed item
----------------------------------------*/
.l-fixedBottom {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
}

.l-fixedTop--header { //ヘッダー用
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
}
