@charset "utf-8";
/*---------------------------------------
component-fixed item
----------------------------------------*/
.c-fixedBottom {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
}
