@charset "utf-8";
/*---------------------------------------
utility-flex
----------------------------------------*/
/*親要素につける*/
.u-flexBetween { /*横並びで両端につける*/
    display: flex;
    justify-content: space-between;
}

.u-flex {
    display: flex;
    column-gap: 10px;
}