@charset "utf-8";
/*---------------------------------------
layout-footer
----------------------------------------*/
.l-footer {
    border-top: 4px solid #3D62AC;

    &__isoArea {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        & img {
            width: 29.33vw;
            height: auto;
        }

        & p {
            margin-top: 1.33vw;
        }
    }

    &__link {
        background: #3D62AC;
        padding: 2.66vw 2.66vw 0;
        color: #fff;

        & ul {
            background: #fff;
            border: 1px solid #aaa;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            & li {
                display: flex;
                align-items: center;
                width: 50%;
                border-bottom: 1px solid #aaa;
                border-right: 1px solid #aaa;
                background: url(/assets/sp/images/icon_arrow_right.png) 97% center no-repeat #fff;
                background-size: 2.1vw auto;

                &:nth-child(2n) {
                    border-right: none;
                }

                &:nth-child(5),
                &:nth-child(6) {
                    border-bottom: none;
                }

                & a {
                    width: 100%;
                    padding: 2.66vw 6.66vw 2.66vw 2.66vw;
                    text-decoration: none;
                    color: #333;
                    font-size: 1.2rem;

                    &.l-footer__linkTwoLines {
                        padding: 1.06vw 6.66vw 1.06vw 2.66vw;
                        line-height: 1.5;
                    }
                }
            }
        }
    }

    &__copyright {
        background: #3D62AC;
        color: #fff;
        padding: 2.66vw;
    }

    //メンテナンス性を考慮し、他サービスのリンク箇所に関してはstyleを流用
    .relation {
        margin:8% auto 5%;
    }
    .relation-logo{
        margin: 0 auto 2%;
        width: 46%;
    }
    .relation-text{
        margin: 0 auto 2%;
        line-height: 1.5;
        text-align: center;
        font-size: 75%;
    }
    .relation ul {
        margin: 0 2.66vw;
        display: flex;
    }
    .relation ul li{
        margin:0 4% 0 0;
        width:31%;
        border: 1px solid #ddd;
        background: #fff;
    }
    .relation ul li:last-child{
        margin:0;
    }
    .relation a {
        padding: 4% 0 3%;
        display:block;
        width:100%;
        text-decoration:none;
    }
    .relation img {
        width:100%;
        height:auto;
        display:block;
    }
    .relation span {
        margin: 0 auto 3%;
        display:block;
    }
    .relation-roujin span{
        width: 30%;
    }
    .relation-gohan span{
        width: 27%;
    }
    .relation-hochouki span{
        width: 24%;
    }
    .relation ul li p {
        margin: 0;
        line-height: 1.5;
        text-align: center;
        color:#07c;
        font-size: 94%;
    }
}