/*---------------------------------------
project-voicelist
----------------------------------------*/
.p-voiceTotalScore {
    background: #EEEEEE;
    padding: 4vw 2.67vw 2.67vw;
    /* .p-voiceTotalScore__area */
    &__area {
        text-align: center;
        border-bottom: 1px solid #aaa;
        padding-bottom: 4vw;
    }
    /* .p-voiceTotalScore__title */
    &__title {
        display: inline-block;
        width: 41.08vw;
        text-align: left;
        &.p-star {
            /* .p-voiceTotalScore__title.p-star--0 */
            &--0 {
                background: url('/assets/sp/images/star_0.png') 100% center no-repeat;
                background-size: 21.6vw;
            }
            /* .p-voiceTotalScore__title.p-star--05 */
            &--05 {
                background: url('/assets/sp/images/star_05.png') 100% center no-repeat;
                background-size: 21.6vw;
            }
            /* .p-voiceTotalScore__title.p-star--1 */
            &--1 {
                background: url('/assets/sp/images/star_1.png') 100% center no-repeat;
                background-size: 21.6vw;
            }
            /* .p-voiceTotalScore__title.p-star--15 */
            &--15 {
                background: url('/assets/sp/images/star_15.png') 100% center no-repeat;
                background-size: 21.6vw;
            }
            /* .p-voiceTotalScore__title.p-star--2 */
            &--2 {
                background: url('/assets/sp/images/star_2.png') 100% center no-repeat;
                right: 0;
                background-size: 21.6vw;
            }
            /* .p-voiceTotalScore__title.p-star--25 */
            &--25 {
                background: url('/assets/sp/images/star_25.png') 100% center no-repeat;
                right: 0;
                background-size: 21.6vw;
            }
            /* .p-voiceTotalScore__title.p-star--3 */
            &--3 {
                background: url('/assets/sp/images/star_3.png') 100% center no-repeat;
                right: 0;
                background-size: 21.6vw;
            }
            /* .p-voiceTotalScore__title.p-star--none */
            &--none {
                position: relative;
                /* .p-voiceTotalScore__title.p-star--none:before */
                &:before {
                    content: "-";
                    position: absolute;
                    right: 6vw;
                }
            }
        }
    }
    /* .p-voiceTotalScore__score */
    &__score {
        margin-left: 2vw;
    }
    /* .p-voiceTotalScore__count */
    &__count {
        font-size: 1.2rem;
    }
    &__detail {
        /* .p-voiceTotalScore__detail.p-voiceBox__reviewWrap */
        &.p-voiceBox__reviewWrap {
            padding: 2.67vw 0 0;
        }
    }
}

.p-voiceBox {
    /* .p-voiceBox__reviewWrap */
    &__reviewWrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 2.66vw;
    }
    /* .p-voiceBox__review */
    &__review {
        width: 46vw;
        font-size: 1.5rem;
        &.p-star {
            /* .p-voiceBox__review.p-star--0 */
            &--0 {
                background: url('/assets/sp/images/star_0.png') 100% 2vw no-repeat;
                background-size: 13.6vw;
            }
            /* .p-voiceBox__review.p-star--05 */
            &--05 {
                background: url('/assets/sp/images/star_05.png') 100% 2vw no-repeat;
                background-size: 13.6vw;
            }
            /* .p-voiceBox__review.p-star--1 */
            &--1 {
                background: url('/assets/sp/images/star_1.png') 100% 2vw no-repeat;
                background-size: 13.6vw;
            }
            /* .p-voiceBox__review.p-star--15 */
            &--15 {
                background: url('/assets/sp/images/star_15.png') 100% 2vw no-repeat;
                background-size: 13.6vw;
            }
            /* .p-voiceBox__review.p-star--2 */
            &--2 {
                background: url('/assets/sp/images/star_2.png') 100% 2vw no-repeat;
                right: 0;
                background-size: 13.6vw;
            }
            /* .p-voiceBox__review.p-star--25 */
            &--25 {
                background: url('/assets/sp/images/star_25.png') 100% 2vw no-repeat;
                right: 0;
                background-size: 13.6vw;
            }
            /* .p-voiceBox__review.p-star--3 */
            &--3 {
                background: url('/assets/sp/images/star_3.png') 100% 2vw no-repeat;
                right: 0;
                background-size: 13.6vw;
            }
            /* .p-voiceBox__review.p-star--none */
            &--none {
                position: relative;
                /* .p-voiceBox__review.p-star--none:before */
                &:before {
                    content: "-";
                    position: absolute;
                    right: 6vw;
                }
            }
        }
    }
}

.p-voiceList {
    /* .p-voiceList .p-voiceBox */
    & .p-voiceBox {
        margin-bottom: 4vw;
        border: 1px solid #aaa;
        border-bottom: 3px solid #aaa;
        border-radius: 4px;
        /* .p-voiceList .p-voiceBox a */
        & a {
            display: block;
            width: 61.33vw;
            background-color: #D4D4D4;
            border-radius: 4px;
            outline: none;
            padding: 2.13vw 0 2.53vw;
            appearance: none;
            box-shadow: 0 0.4rem 0 #AAAAAA;
            color: #333333;
            font-size: 1.8rem;
            text-decoration: none;
            text-align: center;
            margin: 0 auto 5.08vw;
        }
        /* .p-voiceList .p-voiceBox__facilityArea */
        &__facilityArea {
            display: flex;
            align-items: center;
            background:#DEF2FE;
            padding: 2.26vw 2.66vw;
            border-radius: 4px 4px 0 0;
        }
        /* .p-voiceList .p-voiceBox__facilityImage */
        &__facilityImage {
            width: 22.6vw;
            /* .p-voiceList .p-voiceBox__facilityImage img */
            & img {
                max-width: 100%;
            }
        }
        /* .p-voiceList .p-voiceBox__facilityName */
        &__facilityName {
            width: 64.6vw;
            font-size: 1.5rem;
            line-height: 1.5;
        }
        /* .p-voiceList .p-voiceBox__count */
        &__count {
            border-bottom: 1px solid #AAAAAA;
            padding: 0 0 1.9vw;
            /* .p-voiceList .p-voiceBox__count span */
            & span {
                font-weight: bold;
                color: #F81504;
                font-size: 1.85rem;
            }
        }
        /* .p-voiceList .p-voiceBox__date */
        &__date {
            padding: 2.13vw 2.66vw 0;
        }
        /* .p-voiceList .p-voiceBox__title */
        &__title {
            text-decoration: none;
            border-bottom: 1px solid #AAAAAA;
            padding: 0 0 1.9vw;
            color: #333;
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
        /* .p-voiceList .p-voiceBox__profile */
        &__profile {
            padding: 0 2.66vw 3.06vw;
        }
    }
}

