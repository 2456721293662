/*---------------------------------------
utility-margin
----------------------------------------*/
$m5: 1.33vw; //横幅350pxの場合5px
$m10: 2.66vw; //横幅350pxの場合10px
$m15: 4vw; //横幅350pxの場合15px
$m20: 5.32vw; //横幅350pxの場合20px
$m25: 6.66vw; //横幅350pxの場合25px
$m30: 8vw; //横幅350pxの場合30px

.u-mgTop5 { //上に5pxのmargin
    margin-top: $m5;
}

.u-mgTop10 { //上に10pxのmargin
    margin-top: $m10;
}

.u-mgTop15 {
    margin-top: $m15; //上に15pxのmargin
}

.u-mgTop20 {
    margin-top: $m20; //上に20pxのmargin
}

.u-mgTop25 {
    margin-top: $m25; //上に25pxのmargin
}

.u-mgTop30 {
    margin-top: $m30; //上に30pxのmargin
}

.u-mgBtm5 { //下に5pxのmargin
    margin-bottom: $m5;
}

.u-mgBtm10 { //下に10pxのmargin
    margin-bottom: $m10;
}

.u-mgBtm15 {
    margin-bottom: $m15; //下に15pxのmargin
}

.u-mgBtm20 {
    margin-bottom: $m20; //下に20pxのmargin
}

.u-mgBtm25 {
    margin-bottom: $m25; //下に25pxのmargin
}

.u-mgBtm30 {
    margin-bottom: $m30; //下に30pxのmargin
}

.u-mgLtAuto { //左にmargin:auto; ※右寄せにする際使用
    margin-left: auto;
}

.u-mgLt5 { //左に5pxのmargin
    margin-left: $m5;
}

.u-mgLt10 { //左に10pxのmargin
    margin-left: $m10;
}

.u-mgLt15 { //左に15pxのmargin
    margin-left: $m15;
}

.u-mgLt20 { //左に20pxのmargin
    margin-left: $m20;
}

.u-mgLt25 { //左に25pxのmargin
    margin-left: $m25;
}

.u-mgLt30 { //左に30pxのmargin
    margin-left: $m30;
}

.u-mgRtAuto { //右にmargin:auto; ※左寄せにする際使用
    margin-right: auto;
}

.u-mgRt5 { //右に5pxのmargin
    margin-right: $m5;
}

.u-mgRt10 { //右に10pxのmargin
    margin-right: $m10;
}

.u-mgRt15 { //右に15pxのmargin
    margin-right: $m15;
}

.u-mgRt20 { //右に20pxのmargin
    margin-right: $m20;
}

.u-mgRt25 { //右に25pxのmargin
    margin-right: $m25;
}

.u-mgRt30 { //右に30pxのmargin
    margin-right: $m30;
}

.u-mgRtLt10 { //左右に10pxのmargin
    margin-right: $m10;
    margin-left: $m10;
}

.u-mgRtLt15 { //左右に15pxのmargin
    margin-right: $m15;
    margin-left: $m15;
}

.u-mgRtLt20 { //左右に20pxのmargin
    margin-right: $m20;
    margin-left: $m20;
}

.u-mgTopBtm15 { //上下に15pxのmargin
    margin-top: $m15;
    margin-bottom: $m15;
}

.u-mgBox10 { //上下左右に10pxのmargin
    margin: $m10;
}

.u-mgBox15 { //上下左右に15pxのmargin
    margin: $m15;
}

.u-mgBox20 { //上下左右に20pxのmargin
    margin: $m20;
}

.u-mgBox10--20 { //上下10px左右に20pxのmargin
    margin: $m10 $m20;
}
