@charset "utf-8";
/*---------------------------------------
component-pagetop
----------------------------------------*/
.c-pageTop {
    width: 17.3vw;
    height: auto;
    position: fixed;
    right: 3.6vw;
    bottom: 10vw;
    z-index: 100;
}

.c-pageTop__image {
    width: 100%;
    height: auto;
}
