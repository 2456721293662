@charset "utf-8";
/*---------------------------------------
project-pagenation
----------------------------------------*/
.p-pagenation {
    text-align: center;
    display: flex;
    justify-content: center;

    &__item {
        margin-right: 1.33vw;
        padding: 2.3vw 0;
        width: 12.3vw;
        display: block;
        border: 1px solid $lineGrey;

        &.is-inactive {
            color: $textGrey;
            border: 1px solid #D4D4D4;
            background: #D4D4D4;
        }

        &:last-child {
            margin: 0;
        }
    }

    &__link {
        color: $mainBlue;
        background: #fff;
        text-decoration: none;
    }

    &__number {
        font-size: 1.85rem;
    }

    & .is-current {
        color: #fff;
        background: $mainBlue;
        border: 1px solid $mainBlue;
    }
}
