@charset "utf-8";
/*---------------------------------------
component-headline
----------------------------------------*/
.c-headline {
    padding: 4.93vw 2.66vw 6vw;

    &--middle {
        padding: 3.06vw 2.66vw;
    }

    &--narrow {
        padding: 1.73vw 2.66vw;
    }

    &--blue {
        color: #fff;
        background: $mainBlue;
    }

    &--lightBlue {
        background: $bgBlue;
    }

    &--gray {
        background: $bgGrey;
    }

    &--middleHead { //中見出し(帯は無し太文字のみ)
        font-weight: bold;
        line-height: 1.5;
        font-size: $mainFont;
    }

    &--bold{
        font-weight: 700;
        font-size: 1.65rem;
    }

}
